html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-family: "InterVariable", sans-serif;
  background-color: #f0f2f5;
  font-size: 1.6em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

hr {
  border: 0;
  border-bottom: 1px solid #dde0e6;
}

header {
  box-shadow: 0 0 2px 0px rgba(27, 25, 25, 0.5);
}
