button,
select,
input {
  line-height: 1.5;
}

/* Modal styles */
.modal-dialog__content {
  outline: none;
  transform: none !important;
}

.modal-dialog__content > :first-child {
  padding: 2rem;
}

.modal-dialog input {
  flex: 1;
  min-width: 200px;
  width: 460px !important;
}

.modal-dialog select {
  flex: 1;
  min-width: 200px;
  width: 460px !important;
  height: 3.8rem;
  border-color: lightgray !important;
  border: 1px solid;
  border-radius: 2px;
  padding-left: 0.5rem;
}

.dialog-footer button:first-child {
  background-color: #007eb5;
  color: white;
}

/* Autocomplete styles */

.image-case-button-unclicked {
  background-color: inherit;
  width: 50%;
  color: #9a9da0;
  border-bottom: 1px solid #e5e7eb;
  box-shadow: none;
}

.image-case-button-clicked {
  background-color: inherit;
  width: 50%;
  color: #007eb5;
  border-bottom: 1px solid #007eb5;
  box-shadow: none;
}

.query-blue-button {
  background-image: none;
  background-color: #007eb5;
  border: 1px solid #007eb5;
  border-radius: 4px;
  padding: 5px 15px 5px 15px;
  color: #ffffff;
  box-shadow: none;
}

.query-blue-button:hover {
  background-color: #0195d4;
  box-shadow: none;
}

.query-white-button {
  background-color: #ffffff;
  border: 1px solid #007eb5;
  border-radius: 4px;
  padding: 5px 15px 5px 15px;
  color: #007eb5;
  box-shadow: none;
}

.query-comment-textarea {
  border-color: #d1d5db;
  border-radius: 6px;
  resize: vertical;
}

.query-list-header {
  font-weight: 600;
  font-size: 14px;
  color: #52575c;
  padding: 12px 0px 12px 5px;
}

.query-list-result {
  font-weight: 600;
  font-size: 14px;
  padding: 12px 5px 12px 0px;
}

.query-info-listing-text {
  color: #52575c;
}

.menu {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #cccccc;
  z-index: 1;
  background-color: #fff;
  bottom: 4.4rem;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2),
    0 8px 24px rgba(16, 22, 26, 0.2);
}

.item {
  padding: 2px 6px;
  cursor: default;
}

.item-highlighted {
  color: white;
  background-color: #4095bf;
}

.item-header {
  background-color: #eeeeee;
  color: #454545;
  font-weight: bold;
}

/* Loading spinner */

.loader {
  display: inline-block;
  max-width: 50%;
  width: 64px;
  height: 64px;
}
.loader:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #ccc;
  border-color: #ccc transparent #ccc transparent;
  animation: loader 1.2s linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.leaflet-control-attribution {
  display: none;
}

.leaflet-draw-tooltip {
  display: none;
}

.leaflet-tooltip.line-annotation-label {
  background: #0f0;
  border: 1px solid #000;
  border-radius: 0;
  box-shadow: none;
  color: #000;
}

.annotation-popup .leaflet-popup-tip-container {
  display: none;
}

.annotation-popup .leaflet-popup-content-wrapper {
  border-radius: 0;
  padding: 0;
  box-shadow: none;
}

.annotation-popup .leaflet-popup-content-wrapper .leaflet-popup-content {
  margin: 0;
}

.info-sidebar-celiac-table td:not(:last-child),
.info-sidebar-celiac-table th:not(:last-child) {
  border-right: solid thin #e5e5e5;
}

.info-sidebar-celiac-table > tr:not(:last-child) > td,
.info-sidebar-celiac-table > tr:not(:last-child) > th {
  border-bottom: solid thin #e5e5e5;
}

.tooltip {
  z-index: 1;
  width: 110px;
}

.buttontool {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 10px;
  padding-right: 10px;
  border: thin solid black;
  background-color: rgba(120, 120, 120, 0.7);
  color: white;
}

.menutool .tooltip {
  z-index: 1;
  width: 150px;
  background-color: rgba(120, 120, 120, 0.7);
  color: white;
}
.menutool .tooltip-inner span {
  background-color: rgba(120, 120, 120, 0.7);
  color: white;
}

.ttmenu:hover {
  background-color: rgba(120, 120, 120, 0.7);
  color: blue;
}
.ttmenu {
  background-color: rgba(120, 120, 120, 0.7);
  color: white;
}

#microscope-circle {
  transform-origin: center;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}

.colorPickerTool {
  padding: 0.5rem;
}

.colorPicker {
  padding: 1rem;
  height: 5.5rem;
  width: 5.5rem;
  cursor: pointer;
  border: thin solid lightgray;
  margin-left: 1rem;
}

.picker__swatches {
  display: flex;
  max-width: 200px;
  padding: 12px;
  flex-wrap: wrap;
}

.picker__swatch {
  width: 24px;
  height: 24px;
  margin: 4px;
  border: none;
  padding: 0;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
}

.configSection {
  margin-bottom: 24px;
}

.configTextInput {
  width: 250px;
  margin-top: 2px;
  margin-bottom: 4px;
  margin-right: 20px;
  margin-left: 10px;
}

.deletedTextBox {
  height: 3.8rem;
  width: 100%;
  color: gray;
  margin-top: audo;
  margin-bottom: audo;
  padding: 0.8rem;
  border: 1px solid gray;
  text-decoration: line-through;
}

.disabledTextBox {
  height: 3.8rem;
  width: 100%;
  color: gray;
  margin-top: audo;
  margin-bottom: audo;
  padding: 0.8rem;
  border: 1px solid gray;
  font-style: italic;
}

.validationMessage {
  color: red;
  font-size: smaller;
}

.toolButton {
  height: 4rem;
  width: 5.5rem;
  margin: 3px;
  padding: 2px;
}

.crosshair {
  cursor: crosshair;
}

.hidden {
  visibility: hidden;
}

.copyableBox {
  cursor: grab;
}

.highlight_anno_1 {
  color: black;
  -webkit-transition: background-color 400ms linear;
  -ms-transition: background-color 400ms linear;
  transition: background-color 400ms linear;
}

.treeController {
  cursor: pointer;
  padding-top: 4px;
  padding-right: 6px;
  padding-left: 6px;
  float: left;
}

.treeIsOpen .openTree {
  display: block;
}

.treeIsClosed .openTree {
  display: none;
}

.treeIsOpen .closedTree {
  display: none;
}

.treeIsClosed .closedTree {
  display: block;
}

.treeBranchClosed {
  display: none !important;
}

.treeBranchOpen {
  display: block !important;
}

.hangRight {
  float: right;
  cursor: grab;
  padding: 2px;
  padding-right: 4px;
  text-align: right;
}

.treeRow {
  display: block;
}

.treeCell {
  padding-left: 2px;
  padding-top: 2px;
  float: left;
  height: 33px;
}

.highlight_anno_1:hover {
  color: blue;
  background-color: gray;
  /* Firefox */
  x-moz-transition: all 1s ease-in;
  /* WebKit */
  x-webkit-transition: all 1s ease-in;
  /* Opera */
  x-o-transition: all 1s ease-in;
  /* Standard */
  xtransition: all 1s ease-in;
  -webkit-transition: background-color 400ms linear;
  -ms-transition: background-color 400ms linear;
  transition: background-color 400ms linear;
}

/* declaring the states of the animation to transition through */
/* optionally add other properties that will change here, or new states (50% etc) */
@keyframes onHoverAnimation {
  0% {
    background-color: #ccf;
  }
  100% {
    background-color: #abf;
  }
}

#content #nav a {
  background-color: #ff0;

  /* only animation-duration here is required, rest are optional (also animation-name but it will be set on hover)*/
  animation-duration: 200ms; /* same as transition duration */
  animation-timing-function: linear; /* kind of same as transition timing */
  animation-delay: 0ms; /* same as transition delay */
  animation-iteration-count: 1; /* set to 2 to make it run twice, or Infinite to run forever!*/
  animation-direction: normal; /* can be set to "alternate" to run animation, then run it backwards.*/
  animation-fill-mode: none; /* can be used to retain keyframe styling after animation, with "forwards" */
  animation-play-state: running; /* can be set dynamically to pause mid animation*/
}

.strike_through {
  font-style: italic;
  color: gray;
}

#content #nav a:hover {
  /* animation wont run unless the element is given the name of the animation. This is set on hover */
  animation-name: onHoverAnimation;
}

.statBox {
  background: white;
  margin: auto;
  height: 5.5em;
  padding-left: 1em;
  padding-right: 1em;
  border: thin solid #dde0e6;
}

.statNumber {
  text-align: center;
  font-size: 225%;
}

.readonly-button {
  cursor: initial;
}

.readonly-button:hover {
  background-color: rgb(245, 248, 250);
}
